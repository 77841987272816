@font-face {
  font-family: avenir lt std;
  font-style: normal;
  font-weight: 400;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Roman.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: italic;
  font-weight: 400;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Oblique.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: normal;
  font-weight: 300;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Light.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: italic;
  font-weight: 300;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-LightOblique.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: normal;
  font-weight: 350;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Book.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: italic;
  font-weight: 350;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-BookOblique.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: normal;
  font-weight: 600;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Medium.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: italic;
  font-weight: 600;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-MediumOblique.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: normal;
  font-weight: 700;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Heavy.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: italic;
  font-weight: 700;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-HeavyOblique.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: normal;
  font-weight: 750;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-Black.woff) format('woff');
}
@font-face {
  font-family: avenir lt std;
  font-style: italic;
  font-weight: 750;
  src: local('Avenir LT Std'), url(/public/fonts/AvenirLTStd-BlackOblique.woff) format('woff');
}

/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/fonts/DM-Sans-400-1.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/fonts/DM-Sans-400-2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/public/fonts/DM-Sans-500-1.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/public/fonts/DM-Sans-500-2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/public/fonts/DM-Sans-700-1.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/public/fonts/DM-Sans-700-2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
